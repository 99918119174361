import React from 'react'
import { x } from '@xstyled/styled-components'

export function Plus({ ...props }) {
  return (
    <x.svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M12.2549 0.000244141V24.0002" stroke="#CBB294" strokeWidth="2" />
      <path
        d="M24 12.2556L5.96046e-08 12.2556"
        stroke="#CBB294"
        strokeWidth="2"
      />
    </x.svg>
  )
}
