import React from 'react'
import { x, useUp } from '@xstyled/styled-components'
import chainlink from '@pool/assets/logos/chainlink.svg'
import google from '@pool/assets/logos/google.svg'
import ibm from '@pool/assets/logos/ibm.svg'

import xbto from '@pool/assets/logos/xbto.svg'
import tiingo from '@pool/assets/logos/tiingo.svg'
import framework from '@pool/assets/logos/framework.svg'
import dxfeed from '@pool/assets/logos/dxfeed.svg'

import lexisnexis from '@pool/assets/logos/lexisnexis.svg'
import ap from '@pool/assets/logos/ap.svg'
import finage from '@pool/assets/logos/finage.svg'
import accuweather from '@pool/assets/logos/accuweather.svg'
import mastercard from '@pool/assets/logos/mastercard.svg'
import tradermade from '@pool/assets/logos/trademade.svg'
import twelvedata from '@pool/assets/logos/twelvedata.svg'

import dune from '@pool/assets/logos/dune.svg'
import rarity from '@pool/assets/logos/rarity.svg'
import opensea from '@pool/assets/logos/opensea.svg'
import cryptopunks from '@pool/assets/logos/cryptopunks.png'

import { Slider } from '@pool/components/slider/Slider'

function Logo({ src, title, ...props }) {
  return (
    <x.div
      textAlign="center"
      display="flex"
      alignItems="center"
      flexDirection="column"
      p={{ _: '20px', lg: 0 }}
      border={{ _: '1px solid #ffffff42', lg: 'none' }}
      borderRadius="10px"
      justifyContent="center"
      flex={{ _: 1, lg: 'inherit' }}>
      {src && (
        <x.img
          src={src}
          mb={{ _: '0px', lg: '35px' }}
          w={{ _: '100px', lg: 'inherit' }}
          maxHeight={{ _: '40px', lg: 'inherit' }}
          {...props}
        />
      )}
      <x.p
        color="wind"
        fontSize="16px"
        fontWeight="500"
        lineHeight="100%"
        display={{ _: 'none', lg: 'inline' }}
        mt="auto">
        {title}
      </x.p>
    </x.div>
  )
}

function Logos({ id, ...props }) {
  switch (id) {
    case 0:
      return (
        <x.div
          w={{ _: '100%', lg: '540px' }}
          mx={{ _: 'auto', lg: 'inherit' }}
          gap={{ _: '20px', lg: '70px' }}
          display="flex"
          flexWrap="wrap"
          justifyContent="center">
          <Logo src={google} title="Google" />
          <Logo src={ibm} title="IBM" />
          <Logo src={ap} title="Associated Press" />
          <Logo src={accuweather} title="Accuweather" />
        </x.div>
      )
    case 1:
      return (
        <x.div
          w={{ _: '100%', lg: '540px' }}
          mx={{ _: 'auto', lg: 'inherit' }}
          gap={{ _: '20px', lg: '70px' }}
          display="flex"
          flexWrap="wrap"
          justifyContent="center">
          <Logo src={xbto} title="XBTO" />
          <Logo src={tiingo} title="Tiingo" />
          <Logo src={framework} title="Framework" />
          <Logo src={dxfeed} title="dxFeed" />
        </x.div>
      )
    case 2:
      return (
        <x.div
          w={{ _: '100%', lg: '640px' }}
          mx={{ _: 'auto', lg: 'inherit' }}
          gap={{ _: '20px', lg: '70px' }}
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          {...props}>
          <Logo src={chainlink} title="Chainlink" />
          <Logo src={lexisnexis} title="LexisNexis" />
          <Logo src={finage} title="Finage" />
          <Logo src={mastercard} title="Mastercard" />
          <Logo src={tradermade} title="TraderMade" />
          <Logo src={twelvedata} title="TwelveData" />
        </x.div>
      )
    case 3:
      return (
        <x.div
          w={{ _: '100%', lg: '540px' }}
          mx={{ _: 'auto', lg: 'inherit' }}
          gap={{ _: '20px', lg: '70px' }}
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          {...props}>
          <Logo src={dune} title="Dune" h="80px" />
          <Logo src={rarity} title="Rarify" h="70px" />
          <Logo src={opensea} title="OpenSea" h="80px" />
          <Logo
            src={cryptopunks}
            h={{ _: 'auto', lg: '70px' }}
            title="Cryptopunks"
          />
        </x.div>
      )
    default:
      return <></>
  }
}

const list = [
  {
    heading: 'Enterprise consultancy',
    content:
      'Our consultancy insights have been honed by engagements with industry leaders, reshaping paradigms in both the Web3 and mainstream sectors.'
  },
  {
    heading: 'White-labeled solutions',
    content:
      'LinkPool offers custom enterprise white-labeled solutions, ensuring businesses can dive into Web3 seamlessly and with confidence, irrespective of their industry.'
  },
  {
    heading: 'Crypto integrations',
    content:
      'Diving deeper into the heart of crypto, our integration services bridge traditional operations with the revolutionary capabilities of decentralized systems.'
  },
  {
    heading: 'Blockchain analytics',
    content:
      'Data drives LinkPool. With our analytics suite, we decipher the complexities of the crypto realm, presenting clear insights that empower decision-making.'
  }
]

export function Solutions() {
  const upLg = useUp('lg')

  return (
    <x.section
      maxWidth={{ _: '800px', lg: '1288px' }}
      mx="auto"
      mt={{ _: '50px', lg: '100px' }}
      pt={{ _: '50px', lg: '100px' }}
      mb="75px"
      pb="75px"
      id="solutions"
      px={{ _: '30px', '2xl': '0' }}>
      <x.p
        color="chime"
        fontSize={{ _: '20px', lg: '24px' }}
        fontWeight="600"
        lineHeight="100%"
        mb={{ _: '36px', lg: '28px' }}>
        Our solutions
      </x.p>

      <Slider
        animate={upLg ? true : false}
        buttonsProps={
          !upLg
            ? {
                mt: '30px',
                justifyContent: 'center'
              }
            : {}
        }
        minHeight={{ _: '450px', xs: '450px', lg: '375px' }}
        list={list}
        ItemComponent={(item, active) => {
          return (
            <x.div
              w="100%"
              key={active}
              display="flex"
              justifyContent="space-between"
              alignItems="start"
              position={upLg ? 'absolute' : 'inherit'}
              flexDirection={{ _: 'column', lg: 'row' }}>
              <x.div
                mb={{ _: '30px', lg: '0px' }}
                w={{ _: 'auto', lg: '540px' }}>
                <x.h1
                  color="chime"
                  fontSize={{ _: '36px', lg: '70px' }}
                  fontWeight="500"
                  lineHeight="100%"
                  mb={{ _: '16px', lg: '34px' }}>
                  {item.heading}
                </x.h1>
                <x.p
                  color="montezuma"
                  fontSize={{ _: '16px', lg: '22px' }}
                  fontWeight="400"
                  lineHeight="170%">
                  {item.content}
                </x.p>
              </x.div>
              <Logos id={active} />
            </x.div>
          )
        }}
      />
    </x.section>
  )
}
