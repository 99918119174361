import React from 'react'
import { x, useUp } from '@xstyled/styled-components'
import { Slider } from '@pool/components/slider/Slider'
import { addZero } from '@pool/utils/numbers'
import montezumaNoise from '@pool/assets/montezuma-noise.png'

const list = [
  {
    heading: 'Decentralized solutions',
    subheading: 'Future-proof platforms',
    content:
      'Dive into a diverse world of decentralized finance with solutions tailored for both enterprises and individual users.'
  },
  {
    heading: 'Robust network',
    subheading: 'Global reach, local impact',
    content:
      'Ensuring a seamless and trustworthy experience through our expansive and reliable network.'
  },
  {
    heading: 'Cutting-edge tech',
    subheading: ' Driving adoption',
    content:
      'Leveraging the latest in crypto technology to offer game-changing solutions.'
  }
]

export function Features() {
  const upXl = useUp('xl')

  return (
    <x.section
      mt={{ _: '100px', xl: '200px' }}
      py={{ _: '100px', xl: '300px' }}
      px="30px"
      bg="montezuma"
      id="features"
      backgroundImage={`url(${montezumaNoise})`}>
      <x.div
        maxWidth="1288px"
        mx="auto"
        display="flex"
        justifyContent="space-between"
        flexDirection={{ _: 'column', xl: 'row' }}>
        <x.div minWidth={{ _: 'auto', xl: '780px' }}>
          {upXl ? (
            <x.h1
              fontSize="65px"
              fontWeight="500"
              lineHeight="115%"
              color="forest">
              <x.span color="mustard">
                Your experts <br /> for the blockchain.
              </x.span>{' '}
              <br />
              Decentralised
              <br /> solutions.
            </x.h1>
          ) : (
            <x.h1
              mb="50px"
              fontSize="45px"
              fontWeight="500"
              lineHeight="115%"
              color="forest">
              <x.span color="mustard">Your experts for the blockchain.</x.span>
              <br />
              Decentralised solutions.
            </x.h1>
          )}
        </x.div>
        <x.div minWidth={{ _: 'auto', xl: '554px' }}>
          <Slider
            animate={upXl ? true : false}
            buttonVariant="mustard"
            minHeight={{ _: '200px', xl: '250px' }}
            list={list}
            ItemComponent={(item, active) => {
              return (
                <x.div
                  key={item.subheading}
                  minHeight="100px"
                  position={upXl ? 'absolute' : 'inherit'}>
                  <x.h2
                    color="forest"
                    fontSize="24px"
                    fontWeight="600"
                    lineHeight="100%"
                    mb="20px">
                    <x.span mr="5">{addZero(active + 1)}</x.span>
                    {item.subheading}
                  </x.h2>
                  <x.p
                    color="forest"
                    fontSize="22px"
                    fontWeight="400"
                    lineHeight="195%">
                    {item.content}
                  </x.p>
                </x.div>
              )
            }}
          />
        </x.div>
      </x.div>
    </x.section>
  )
}
