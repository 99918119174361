import React from 'react'
import { x } from '@xstyled/styled-components'

export function Minus({ ...props }) {
  return (
    <x.svg
      width="24"
      height="3"
      viewBox="0 0 24 3"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M24 1.25543L5.96046e-08 1.25543"
        stroke="#CBB294"
        strokeWidth="2"
      />
    </x.svg>
  )
}
