import React from 'react'
import { x } from '@xstyled/styled-components'
import { Button } from '@pool/components/button/Button'
import { ArrowRightLong } from '@pool/components/assets/ArrowRightLong'
import { slideToId } from '@pool/utils/slideToId'

export function Hero() {
  return (
    <x.section
      maxWidth="1020px"
      mx="auto"
      mt={{ _: '40px', sm: '80px' }}
      mb="75px"
      px={{ _: '30px', xl: '0' }}>
      <x.p
        fontWeight="600"
        fontSize={{ _: '20px', lg: '24px' }}
        color="chime"
        mb="25px"
        lineHeight={{ _: '130%', sm: '100%' }}
        ml="10px">
        Leading the crypto ecosystem's next evolution.
      </x.p>
      <x.h1
        fontSize={{ _: '50px', sm: '70px', xl: '107px' }}
        fontWeight="500"
        lineHeight="115%"
        color="chime"
        mb="40px">
        Empower your Web3 journey with LinkPool.
      </x.h1>
      <Button
        onClick={() => slideToId('solutions')}
        display="flex"
        spaceX={{ _: '20px', sm: '50px' }}
        variant="wind"
        py="0"
        px={{ _: '20px', sm: '50px' }}
        fontWeight="600"
        fontSize={{ _: '16px', sm: '20px' }}>
        <x.span>Dive In Today</x.span>
        <x.span
          border="default"
          borderColor="inherit"
          w="2px"
          h={{ _: '46px' }}
        />
        <ArrowRightLong />
      </Button>
    </x.section>
  )
}
