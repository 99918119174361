import React from 'react'
import { x } from '@xstyled/styled-components'
import community from '@pool/assets/community.svg'

export function Community() {
  return (
    <x.section
      maxWidth="1106px"
      mx="auto"
      pt={{ _: '75px', lg: '150px' }}
      px="30px"
      id="community">
      <x.div
        display="flex"
        justifyContent="space-between"
        flexDirection={{ _: 'column-reverse', lg: 'row' }}>
        <x.div
          display="flex"
          justifyContent="center"
          alignItems="center"
          mr={{ _: '0px', lg: '110px' }}
          minWidth={{ _: '0px', lg: '465px' }}
          minHeight={{ _: '0px', lg: '465px' }}
          bg="#31685552"
          borderRadius="35px">
          <x.img src={community} w="264px" py="50px" />
        </x.div>
        <x.div mb={{ _: '25px', sm: '50px' }}>
          <x.h2
            color="chime"
            fontSize={{ _: '20px', sm: '24px' }}
            fontWeight="600"
            lineHeight="130%"
            mb={{ _: '25px', sm: '50px' }}>
            Empowering through knowledge & community
          </x.h2>
          <x.p
            color="montezuma"
            fontSize={{ _: '18px', sm: '22px' }}
            fontWeight="300"
            lineHeight="170%">
            At LinkPool, education meets community engagement. Dive into
            resources tailored for both newcomers and crypto experts, and
            connect with like-minded individuals through our dynamic events and
            discussions. From foundational articles to global meetups, we're
            here to guide and connect every step of the way.
          </x.p>
        </x.div>
      </x.div>
    </x.section>
  )
}
