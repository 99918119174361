import React from 'react'
import { x, useUp } from '@xstyled/styled-components'
import logotype from '@pool/assets/logotype.svg'
import { Button, ButtonUnstyled } from '@pool/components/button/Button'
import { slideToId } from '@pool/utils/slideToId'
import contact from '@pool/assets/contact.svg'

export function Navbar() {
  const upSm = useUp('sm')

  return (
    <x.div
      w="auto"
      mx={{ _: '30px', sm: '50px' }}
      py={{ _: '30px', sm: '50px' }}
      id="navbar">
      <x.div display="flex" justifyContent="space-between" alignItems="center">
        <x.img
          src={logotype}
          w={{ _: '180px', sm: '230px' }}
          title="linkpool"
        />
        <x.nav display="flex" alignItems="center" spaceX="80px">
          {upSm ? (
            <Button
              variant="default"
              size={!upSm ? 'sm' : 'default'}
              onClick={() => slideToId('footer')}>
              Contact Us
            </Button>
          ) : (
            <ButtonUnstyled>
              <x.img
                src={contact}
                w="33px"
                onClick={() => slideToId('footer')}
              />
            </ButtonUnstyled>
          )}
        </x.nav>
      </x.div>
    </x.div>
  )
}
