import React from 'react'
import { x } from '@xstyled/styled-components'
import vision from '@pool/assets/vision.svg'

export function Vision() {
  return (
    <x.section
      maxWidth="1106px"
      mx="auto"
      mt={{ _: '75px', lg: '150px' }}
      pt={{ _: '0px', lg: '150px' }}
      px="30px"
      id="vision">
      <x.div
        display="flex"
        justifyContent="space-between"
        flexDirection={{ _: 'column', lg: 'row' }}>
        <x.div>
          <x.h2
            color="chime"
            fontSize={{ _: '20px', sm: '24px' }}
            fontWeight="600"
            lineHeight="100%"
            mb={{ _: '25px', sm: '50px' }}>
            Where innovation meets aspiration
          </x.h2>
          <x.p
            color="montezuma"
            fontSize={{ _: '18px', sm: '22px' }}
            fontWeight="300"
            lineHeight="170%">
            LinkPool's inception was not just about joining the Web3 trend, but
            about reshaping it. Our journey, deeply rooted in the rich history
            of Chainlink's ecosystem, propels us to dream big and create
            solutions that are both transformative and sustainable.
          </x.p>
        </x.div>
        <x.div
          display="flex"
          justifyContent="center"
          alignItems="center"
          ml={{ _: '0px', lg: '110px' }}
          mt={{ _: '30px', lg: '0' }}
          minWidth={{ _: '0px', lg: '465px' }}
          minHeight={{ _: '0px', lg: '465px' }}
          bg="#31685552"
          borderRadius="35px">
          <x.img src={vision} w="240px" py="50px" />
        </x.div>
      </x.div>
    </x.section>
  )
}
