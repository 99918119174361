import React from 'react'
import { x } from '@xstyled/styled-components'

import chainlayer from '@pool/assets/logos/chainlayer.svg'
import cryptomanufaktur from '@pool/assets/logos/cryptomanufaktur.svg'
import framework from '@pool/assets/logos/framework.svg'
import inotel from '@pool/assets/logos/inotel.svg'
import linkforest from '@pool/assets/logos/linkforest.svg'
import linkriver from '@pool/assets/logos/linkriver.svg'
import matrixed from '@pool/assets/logos/matrixed.svg'
import piertwo from '@pool/assets/logos/piertwo.svg'
import orionmoney from '@pool/assets/logos/orionmoney.svg'
import simplystaking from '@pool/assets/logos/simplystaking.svg'
import stakefish from '@pool/assets/logos/stakefish.svg'
import stakin from '@pool/assets/logos/stakin.svg'
import tiingo from '@pool/assets/logos/tiingo.svg'
import zero1node from '@pool/assets/logos/zero1node.svg'

function Logo({ src, title, ...props }) {
  return (
    <x.div
      w={{ _: '50px', sm: '130px' }}
      textAlign="center"
      display="flex"
      alignItems="center"
      flexDirection="column">
      {src && (
        <x.img
          src={src}
          h={{ _: '40px', sm: 'inherit' }}
          mb="35px"
          {...props}
        />
      )}
      <x.p
        display={{ _: 'none', sm: 'inline' }}
        color="wind"
        fontSize="16px"
        fontWeight="500"
        lineHeight="100%"
        mt="auto">
        {title}
      </x.p>
    </x.div>
  )
}

export function Ecosystem() {
  return (
    <x.section
      maxWidth="1348px"
      mx="auto"
      mt={{ _: '40px', sm: '75px' }}
      id="ecosystem"
      textAlign="center"
      px={{ _: '30px', xl: '0' }}>
      <x.div borderTop="1px solid #ffffff40" pt={{ _: '75px', sm: '150px' }}>
        <x.p
          color="chime"
          fontSize={{ _: '20px', sm: '24px' }}
          fontWeight="600"
          lineHeight="100%"
          mb={{ _: '36px', lg: '28px' }}>
          A Unified Vision
        </x.p>
        <x.h1
          color="chime"
          fontSize={{ _: '50px', sm: '70px' }}
          fontWeight="500"
          lineHeight="100%"
          mb={{ _: '16px', lg: '34px' }}>
          Ecosystem collaboration
        </x.h1>
        <x.p
          color="montezuma"
          fontSize={{ _: '16px', sm: '22px' }}
          fontWeight="400"
          lineHeight="170%"
          maxWidth="1200px"
          mx="auto">
          The strength of LinkPool lies in collaboration. With partners,
          operators, and the wider crypto community, we're forging an
          interconnected future for decentralized systems.
        </x.p>
        <x.div
          maxWidth="1288px"
          rowGap={{ _: '10px', sm: '100px' }}
          columnGap={{ _: '40px', sm: '60px' }}
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          mt={{ _: '70px', sm: '140px' }}>
          <Logo src={chainlayer} title="ChainLayer" />
          <Logo src={cryptomanufaktur} title="CryptoManufaktur" />
          <Logo src={framework} title="Framework" />
          <Logo src={inotel} title="Inotel" />
          <Logo src={linkforest} title="LinkForest.io" />
          <Logo src={linkriver} title="LinkRiver" />
          <Logo src={matrixed} title="Matrixed.Link" />
          <Logo src={piertwo} title="PIERTWO" />
          <Logo src={orionmoney} title="Orion Money" />
          <Logo src={simplystaking} title="Simply Staking" />
          <Logo src={stakefish} title="Stakefish" />
          <Logo src={stakin} title="Stakin" />
          <Logo src={tiingo} title="Tiingo" />
          <Logo src={zero1node} title="01NODE" />
        </x.div>
      </x.div>
    </x.section>
  )
}
