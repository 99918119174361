import React from 'react'
import { x } from '@xstyled/styled-components'
import card1icon from '@pool/assets/card1.svg'
import card2icon from '@pool/assets/card2.svg'
import card3icon from '@pool/assets/card3.svg'

const list = [
  {
    heading: (
      <>
        Liquid <br />
        staking
      </>
    ),
    meta: 'Maximize Your Returns',
    content:
      'Stake effortlessly and earn rewards, offering the flexibility that modern finance demands.',
    icon: card1icon
  },
  {
    heading: (
      <>
        Crypto <br />
        integration
      </>
    ),
    meta: 'Seamless Interactions',
    content:
      'Unite your ventures with the crypto world, ensuring efficient and transformative integrations.',
    icon: card2icon
  },
  {
    heading: (
      <>
        Community <br />
        engagement
      </>
    ),
    meta: 'Shape the Future Together',
    content:
      'Immerse yourself in a vibrant community, sharing insights and collectively steering the crypto landscape.',
    icon: card3icon
  }
]

function Card({ card }) {
  return (
    <x.article
      bg="#31685552"
      px="35px"
      py={{ _: '35px', lg: '55px' }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      borderRadius="35px">
      <x.h2
        textAlign="center"
        fontSize={{ _: '24px', lg: '36px' }}
        fontWeight="600"
        lineHeight="110%"
        color="chime">
        {card.heading}
      </x.h2>
      <x.img
        src={card.icon}
        w="170px"
        mt={{ _: '20px', lg: '37px' }}
        mb={{ _: '20px', lg: '37px' }}
      />
      <x.p
        maxWidth={{ _: '400px', lg: 'inherit' }}
        textAlign="center"
        fontSize={{ _: '18px', lg: '23px' }}
        fontWeight="300"
        lineHeight="170%"
        color="montezuma">
        {card.content}
      </x.p>
    </x.article>
  )
}

export function Services() {
  return (
    <x.section
      maxWidth="1348px"
      mx="auto"
      mt={{ _: '75px', xl: '150px' }}
      pt={{ _: '75px', xl: '150px' }}
      id="services">
      <x.div
        w="100%"
        display="grid"
        gap="50px"
        px="30px"
        gridTemplateColumns={{ _: 1, lg: 3 }}>
        {list.map((item) => (
          <Card key={item.content} card={item} />
        ))}
      </x.div>
    </x.section>
  )
}
