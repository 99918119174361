// Default theme: https://github.com/gregberge/xstyled/blob/main/packages/system/src/defaultTheme.ts

import { defaultTheme, createGlobalStyle } from '@xstyled/styled-components'

const defaultColors = {
  body: '#093331',
  space: '#093331',
  montezuma: '#E8D7C4',
  grandMontezuma: '#F2EAE0',
  wind: '#6B9385',
  chime: '#CBB294',
  milo: '#E7EBE5',
  salvador: '#316855',
  forest: '#316855',
  vandog: '#9FAB98',
  mustard: '#A47843'
}

const darkColors = {}

const space = {
  0.5: '0.125rem', // 2px
  1: '0.25rem', // 4px
  1.5: '0.375rem', // 6px
  2: '0.5rem', // 8px
  2.5: '0.625rem', // 10px
  3: '0.75rem', // 12px
  3.5: '0.875rem', // 14px
  4: '1rem', // 16px
  5: '1.25rem', // 20px
  6: '1.5rem', // 24px
  7: '1.75rem', // 28px
  8: '2rem', // 32px
  9: '2.25rem', // 36px
  10: '2.5rem', // 40px
  11: '2.75rem', // 44px
  12: '3rem', // 48px
  14: '3.5rem', // 56px
  16: '4rem', // 64px
  20: '5rem', // 80px
  24: '6rem', // 96px
  28: '7rem', // 112px
  32: '8rem', // 128px
  36: '9rem', // 144px
  40: '10rem', // 160px
  44: '11rem',
  48: '12rem',
  52: '13rem',
  56: '14rem',
  60: '15rem',
  64: '16rem',
  72: '18rem',
  80: '20rem',
  96: '24rem'
}

const screens = {
  _: 0,
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1252,
  '2xl': 1600,
  '3xl': 2100
}

const sizes = {
  maxWidth: 1792,
  tight: 1536,
  slim: 1252
}

const transitionProperties = {
  buttons: ['box-shadow', 'border-color', 'background-color', 'width']
}

const shadows = {
  'pool-1': '0px 4px 15px rgba(0, 0, 0, 0.06)'
}

export const theme = {
  defaultColorModeName: 'default',
  ...defaultTheme,
  useColorSchemeMediaQuery: false,
  colors: {
    ...defaultTheme.colors,
    ...defaultColors,
    modes: {
      dark: darkColors
    }
  },
  fonts: {
    ...defaultTheme.fonts,
    sans: `'Montserrat', sans-serif;`
  },
  transitionProperties: {
    ...defaultTheme.transitionProperties,
    ...transitionProperties
  },
  sizes: {
    ...defaultTheme.sizes,
    ...sizes
  },
  space,
  shadows: {
    ...defaultTheme.shadows,
    ...shadows
  },
  screens
}

export const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Montserrat', sans-serif!important;
    background-color: body;
    background: #093331 url("/space-noise.png") repeat;


  }
  .grecaptcha-badge { visibility: hidden; }
`
