import React from 'react'
import styled, { x } from '@xstyled/styled-components'
import { Button } from '@pool/components/button/Button'
import { ArrowRight } from '@pool/components/assets/ArrowRight'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

export const SliderStyles = styled.divBox`
  overflow: hidden;
  position: relative;

  .right-to-left-enter {
    transform: translateX(100%);
    opacity: 0;
  }
  .right-to-left-enter-active {
    transform: translateX(0);
    transition: all 200ms ease;
    opacity: 1;
  }

  .right-to-left-exit {
    transform: translateX(0);
    opacity: 1;
  }
  .right-to-left-exit-active {
    transform: translateX(-100%);
    transition: all 200ms ease;
    opacity: 0;
  }

  .left-to-right-enter {
    transform: translateX(-100%);
  }
  .left-to-right-enter-active {
    transform: translateX(0);
    transition: all 200ms ease;
  }

  .left-to-right-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .left-to-right-exit-active {
    transform: translateX(100%);
    transition: all 200ms ease;
    opacity: 0;
  }
`
export function Slider({
  animate = true,
  list,
  ItemComponent,
  buttonVariant = 'chime',
  buttonsProps = {},
  ...props
}) {
  const [active, setActive] = React.useState(0)
  const [isNext, setIsNext] = React.useState(true)

  function handleStep(nextStep, direction) {
    setIsNext(direction === 'left' ? true : false)
    setActive(() => {
      return nextStep > list.length - 1
        ? 0
        : nextStep < 0
        ? list.length - 1
        : nextStep
    })
  }

  const elementRef = React.useRef()

  return (
    <x.div position="relative" ref={elementRef}>
      {animate ? (
        <SliderStyles {...props}>
          <TransitionGroup
            childFactory={(child) =>
              React.cloneElement(child, {
                classNames: isNext ? 'right-to-left' : 'left-to-right',
                timeout: 200
              })
            }>
            {list
              .filter((_, i) => i === active)
              .map((item) => {
                const itemRef = React.createRef(null)
                return (
                  <CSSTransition
                    nodeRef={itemRef}
                    key={active}
                    classNames="right-to-left"
                    timeout={200}>
                    <x.div w="100%" ref={itemRef} key={active}>
                      {ItemComponent(item, active)}
                    </x.div>
                  </CSSTransition>
                )
              })}
          </TransitionGroup>
        </SliderStyles>
      ) : (
        list
          .filter((_, i) => i === active)
          .map((item) => {
            const itemRef = React.createRef(null)
            return (
              <CSSTransition
                nodeRef={itemRef}
                key={active}
                classNames="right-to-left"
                timeout={200}>
                <x.div w="100%" ref={itemRef} key={active}>
                  {ItemComponent(item, active)}
                </x.div>
              </CSSTransition>
            )
          })
      )}

      <x.div
        display="flex"
        spaceX={{ _: '20px', lg: '40px' }}
        mt={{ _: '40px', lg: '10px' }}
        {...buttonsProps}>
        <Button
          variant={buttonVariant}
          h={{ _: '54px', lg: '64px' }}
          w={{ _: '54px', lg: '64px' }}
          onClick={() => handleStep(active - 1, 'right')}>
          <ArrowRight
            transform
            rotate="180"
            position={{ _: 'absolute', lg: 'inherit' }}
            w={{ _: '26px', lg: 'inherit' }}
            h={{ _: '26px', lg: 'inherit' }}
          />
        </Button>
        <Button
          variant={buttonVariant}
          h={{ _: '54px', lg: '64px' }}
          w={{ _: '54px', lg: '64px' }}
          onClick={() => handleStep(active + 1, 'left')}>
          <ArrowRight
            position={{ _: 'absolute', lg: 'inherit' }}
            w={{ _: '26px', lg: 'inherit' }}
            h={{ _: '26px', lg: 'inherit' }}
          />
        </Button>
      </x.div>
    </x.div>
  )
}
