import React from 'react'
import styled, { x } from '@xstyled/styled-components'
import { Button as AriaButton } from '@ariakit/react'
import { useColorMode } from '@xstyled/styled-components'

const buttonBaseStyle = {
  position: 'relative',
  display: ' inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration: 'none',
  textAlign: 'center',
  cursor: 'pointer',
  borderWidth: '2',
  borderStyle: 'solid',
  lineHeight: 1,
  outline: { focus: 'none' },
  borderRadius: 'full',
  opacity: { _: 1, disabled: 0.5 },
  appearance: 'none',
  overflow: 'hidden'
}

const styles = ({ colorMode }) => {
  const light = {
    wind: {
      color: {
        _: 'wind',
        hover: 'montezuma',
        focus: 'montezuma'
      },
      background: 'transparent',
      borderColor: {
        _: 'wind',
        hover: 'montezuma',
        focus: 'montezuma'
      }
    },
    forest: {
      color: {
        _: 'forest',
        hover: 'space',
        focus: 'space'
      },
      background: 'transparent',
      borderColor: {
        _: 'forest',
        hover: 'space',
        focus: 'space'
      }
    },
    mustard: {
      color: {
        _: 'mustard',
        hover: 'forest',
        focus: 'forest'
      },
      background: 'transparent',
      borderColor: {
        _: 'mustard',
        hover: 'forest',
        focus: 'forest'
      }
    },
    vandog: {
      color: {
        _: 'vandog',
        hover: 'forest',
        focus: 'forest'
      },
      background: 'transparent',
      borderColor: {
        _: 'vandog',
        hover: 'forest',
        focus: 'forest'
      }
    },
    default: {
      color: {
        _: 'chime',
        hover: 'montezuma',
        focus: 'montezuma'
      },
      background: 'transparent',
      borderColor: {
        _: 'chime',
        hover: 'montezuma',
        focus: 'montezuma'
      }
    }
  }

  const dark = {}

  return colorMode === 'dark' ? dark : light
}

const sizes = () => {
  return {
    default: {
      px: '20px',
      py: '15px',
      fontSize: '18px'
    },
    sm: { px: '16px', py: '12px', fontSize: '16px' }
  }
}
export const Button = React.forwardRef(function Button(
  { variant = 'default', size = 'default', children, ...props },
  ref
) {
  const [colorMode] = useColorMode()
  let buttonStyle =
    styles({ colorMode })[variant] || styles({ colorMode })['default']

  let buttonSize = sizes()[size] || sizes()['default']

  return (
    <x.button
      ref={ref}
      as={AriaButton}
      {...buttonBaseStyle}
      {...buttonStyle}
      {...buttonSize}
      {...props}>
      {children}
    </x.button>
  )
})

export const ButtonUnstyled = styled.buttonBox`
  border-radius: 0;
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  border-spacing: 0;
  font-size: 16px;
  font-weight: normal;
  line-height: 100%;
  list-style: none outside none;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  &:focus {
    outline: none;
  }
`
