import React from 'react'
import { x } from '@xstyled/styled-components'

export function ArrowRight({ ...props }) {
  return (
    <x.svg
      width="28"
      height="16"
      viewBox="0 0 28 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3431 0.92888L27.7071 7.29284C28.0976 7.68336 28.0976 8.31653 27.7071 8.70705L21.3431 15.071C20.9526 15.4615 20.3195 15.4615 19.9289 15.071C19.5384 14.6805 19.5384 14.0473 19.9289 13.6568L24.5858 8.99995L1 8.99995C0.447715 8.99995 0 8.55223 0 7.99995C0 7.44766 0.447715 6.99995 1 6.99995L24.5858 6.99995L19.9289 2.34309C19.5384 1.95257 19.5384 1.3194 19.9289 0.92888C20.3195 0.538355 20.9526 0.538355 21.3431 0.92888Z"
      />
    </x.svg>
  )
}
