import React from 'react'
import { x } from '@xstyled/styled-components'
import { Navbar } from '@pool/components/navbar/Navbar'
import { Hero } from '@pool/components/hero/Hero'
import { Features } from '@pool/components/features/Features'
import { Faq } from '@pool/components/faq/Faq'
import { Services } from '@pool/components/services/Services'
import { Testimonials } from '@pool/components/testimonials/Testimonials'
import { Vision } from '@pool/components/vision/Vision'
import { Community } from '@pool/components/community/Community'
import { Solutions } from '@pool/components/solutions/Solutions'
import { Footer } from '@pool/components/footer/Footer'
import { Metrics } from '@pool/components/metrics/Metrics'
import { Ecosystem } from '@pool/components/ecosystem/Ecosystem'
import { Blockchains } from '@pool/components/blockchains/Blockchains'

export function LandingPage() {
  return (
    <x.main>
      <Navbar />
      <Hero />
      <Solutions />
      <Ecosystem />
      <Features />
      <Services />
      <Faq />
      <Testimonials />
      <Metrics />
      <Vision />
      <Community />
      <Blockchains />
      <Footer />
    </x.main>
  )
}
