import React from 'react'
import { x } from '@xstyled/styled-components'

import arbitrum from '@pool/assets/logos/arbitrum.svg'
import avalanche from '@pool/assets/logos/avalanche.svg'
import base from '@pool/assets/logos/base.svg'
import bitcoin from '@pool/assets/logos/bitcoin.svg'
import bsc from '@pool/assets/logos/bsc.svg'
import ethereum from '@pool/assets/logos/ethereum.svg'
import fantom from '@pool/assets/logos/fantom.svg'
import gnosis from '@pool/assets/logos/gnosis.svg'
import harmony from '@pool/assets/logos/harmony.svg'
import metisdao from '@pool/assets/logos/metisdao.svg'
import moonbeam from '@pool/assets/logos/moonbeam.svg'
import moonriver from '@pool/assets/logos/moonriver.svg'
import optimism from '@pool/assets/logos/optimism.svg'
import polygonzkEVM from '@pool/assets/logos/polygon-zkEVM.svg'
import polygon from '@pool/assets/logos/polygon.svg'
import solana from '@pool/assets/logos/solana.svg'

function Logo({ src, title, ...props }) {
  return (
    <x.div
      w={{ _: '50px', sm: '100px' }}
      textAlign="center"
      display="flex"
      alignItems="center"
      flexDirection="column">
      {src && (
        <x.img
          src={src}
          h={{ _: '40px', sm: 'inherit' }}
          mb="35px"
          {...props}
        />
      )}
      <x.p
        display={{ _: 'none', sm: 'inline' }}
        color="wind"
        fontSize="16px"
        fontWeight="500"
        lineHeight="170%">
        {title}
      </x.p>
    </x.div>
  )
}

export function Blockchains() {
  return (
    <x.section
      maxWidth="1348px"
      mx="auto"
      mt={{ _: '40px', sm: '200px' }}
      id="ecosystem"
      textAlign="center"
      px={{ _: '30px', xl: '0' }}>
      <x.p
        color="chime"
        fontSize={{ _: '20px', sm: '24px' }}
        fontWeight="600"
        lineHeight="100%"
        mb={{ _: '36px', lg: '28px' }}>
        Blockchains
      </x.p>
      <x.h1
        color="chime"
        fontSize={{ _: '50px', sm: '70px' }}
        fontWeight="500"
        lineHeight="100%"
        mb={{ _: '16px', lg: '34px' }}>
        Chains we support
      </x.h1>
      <x.p
        color="montezuma"
        fontSize={{ _: '16px', sm: '22px' }}
        fontWeight="400"
        lineHeight="170%">
        LinkPool's cutting-edge, on-premise stack ensures top-tier performance
        and unmatched security across multiple blockchain ecosystems. With
        redundant failover systems and up to next-gen connectivity, we champion
        seamless interoperability and resilience.
      </x.p>
      <x.div
        maxWidth="1288px"
        rowGap={{ _: '10px', sm: '100px' }}
        columnGap={{ _: '40px', sm: '60px' }}
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        mt={{ _: '70px', sm: '140px' }}>
        <Logo src={arbitrum} title="Arbitrum" />
        <Logo src={avalanche} title="Avalanche" />
        <Logo src={base} title="Base" />
        <Logo src={bitcoin} title="Bitcoin" />
        <Logo src={bsc} title="BSC" />
        <Logo src={ethereum} title="Ethereum" />
        <Logo src={fantom} title="Fantom" />
        <Logo src={metisdao} title="Metis" />
        <Logo src={gnosis} title="Gnosis" />
        <Logo src={harmony} title="Harmony" />
        <Logo src={moonbeam} title="Moonbeam" />
        <Logo src={moonriver} title="Moonriver" />
        <Logo src={optimism} title="Optimism" />
        <Logo src={polygonzkEVM} title="Polygon zkEVM" />
        <Logo src={polygon} title="Polygon" />
        <Logo src={solana} title="Solana" />
      </x.div>
    </x.section>
  )
}
