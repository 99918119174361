import React from 'react'
import { x } from '@xstyled/styled-components'
import sdl from '@pool/assets/sdl.svg'

export function Metrics() {
  return (
    <x.section
      maxWidth="1560"
      mx="auto"
      mt={{ _: '75px', xl: '150px' }}
      pt={{ _: '75px', xl: '150px' }}
      id="metrics"
      px="30px">
      <x.h1
        mb={{ _: '40px', xl: '130px' }}
        fontSize={{ _: '35px', xl: '70px' }}
        maxWidth={{ _: '280px', xl: '580px' }}
        mx="auto"
        fontWeight="500"
        lineHeight="110%"
        color="chime"
        textAlign="center">
        Navigating the crypto frontier
      </x.h1>
      <x.div
        display="flex"
        justifyContent="center"
        flexDirection={{ _: 'column', xl: 'row' }}>
        <x.div
          px="60px"
          display="flex"
          flexDirection={{ _: 'column', xl: 'row' }}
          justifyContent="center"
          alignItems="center"
          borderRight={{ _: 'none', xl: '1px solid #ffffff42' }}
          borderBottom={{ _: '1px solid #ffffff42', xl: 'none' }}
          mb={{ _: '40px', xl: 0 }}
          pb={{ _: '40px', xl: 0 }}
          textAlign={{ _: 'center', xl: 'left' }}>
          <x.p
            mr={{ _: '0', xl: '35px' }}
            mb={{ _: '20px', xl: '0' }}
            fontSize={{ _: '30px', xl: '55px' }}
            fontWeight="500"
            lineHeight="100%"
            color="chime">
            15+
          </x.p>
          <x.p
            w="131px"
            fontSize={{ _: '20px', xl: '24px' }}
            fontWeight="500"
            lineHeight="130%"
            color="chime">
            Validating networks
          </x.p>
        </x.div>
        <x.div
          px="60px"
          display="flex"
          flexDirection={{ _: 'column', xl: 'row' }}
          justifyContent="center"
          alignItems="center"
          borderRight={{ _: 'none', xl: '1px solid #ffffff42' }}
          borderBottom={{ _: '1px solid #ffffff42', xl: 'none' }}
          mb={{ _: '40px', xl: 0 }}
          pb={{ _: '40px', xl: 0 }}
          textAlign={{ _: 'center', xl: 'left' }}>
          <x.p
            mr={{ _: '0', xl: '35px' }}
            mb={{ _: '20px', xl: '0' }}
            fontSize={{ _: '30px', xl: '55px' }}
            fontWeight="500"
            lineHeight="100%"
            color="chime">
            6+ years
          </x.p>
          <x.p
            w={{ _: '154px', xl: '190px' }}
            fontSize={{ _: '20px', xl: '24px' }}
            fontWeight="500"
            lineHeight="130%"
            color="chime">
            Leading crypto infrastructure
          </x.p>
        </x.div>
        <x.div
          px="60px"
          display="flex"
          flexDirection={{ _: 'column', xl: 'row' }}
          justifyContent="center"
          alignItems="center"
          mb={{ _: '40px', xl: 0 }}
          pb={{ _: '40px', xl: 0 }}
          textAlign={{ _: 'center', xl: 'left' }}>
          <x.img
            w={{ _: '25px', xl: '49px' }}
            src={sdl}
            mr={{ _: '0', xl: '35px' }}
            mb={{ _: '20px', xl: 0 }}
          />
          <x.p
            w="154px"
            fontSize={{ _: '20px', xl: '24px' }}
            fontWeight="500"
            lineHeight="130%"
            color="chime">
            Founders of stake.link
          </x.p>
        </x.div>
      </x.div>
    </x.section>
  )
}
