import React from 'react'
import { x } from '@xstyled/styled-components'
import { Button } from '@pool/components/button/Button'
import { ArrowRight } from '@pool/components/assets/ArrowRight'
import { ContactForm } from './ContactForm'
import linkedin from '@pool/assets/linkedin.png'
import tele from '@pool/assets/tele.png'
import twitter from '@pool/assets/twitter.png'
import { slideToId } from '@pool/utils/slideToId'

export function Footer() {
  return (
    <x.footer
      mt="155px"
      pt={{ _: '75px', lg: '125px' }}
      bg="montezuma"
      id="footer">
      <x.div
        px={{ _: '30px', '2xl': 0 }}
        maxWidth="1495px"
        mx="auto"
        mb="40px"
        display="flex"
        justifyContent="space-between">
        <x.h1
          maxWidth="1024px"
          fontSize={{ _: '40px', lg: '60px' }}
          fontWeight="600"
          lineHeight="115%"
          color="forest">
          LinkPool - charting the course for the new age of Web3.
        </x.h1>
        <Button
          display={{ _: 'none', lg: 'flex' }}
          right="50px"
          position="absolute"
          variant="vandog"
          h="64px"
          w="64px"
          transform
          p="0"
          rotate="-90"
          onClick={() => slideToId('navbar')}>
          <ArrowRight w="30px" />
        </Button>
      </x.div>

      <x.div
        px={{ _: '30px', '2xl': 0 }}
        maxWidth="1495px"
        mx="auto"
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        mb={{ _: '60px', lg: '100px' }}>
        <x.div w={{ _: '100%', lg: '940px' }} mr={{ _: '0px', lg: '300px' }}>
          <ContactForm />
        </x.div>
        <x.div minWidth="400px" mt="60px" display="flex" flexDirection="column">
          <x.div display="flex" flexDirection="column" spaceY="25px"></x.div>
          <x.div
            display="flex"
            spaceX={{ _: '30px', lg: '30px' }}
            marginTop="auto">
            <x.a
              href="https://www.linkedin.com/company/linkpool/"
              target="_blank"
              rel="noopener noreferrer"
              opacity={{ _: 1, hover: 0.4 }}
              aria-label="Linkedin LinkPool"
              name="Linkedin LinkPool">
              <x.img src={linkedin} w="40px" h="40px"></x.img>
            </x.a>
            <x.a
              href="https://twitter.com/linkpoolio"
              target="_blank"
              rel="noopener noreferrer"
              opacity={{ _: 1, hover: 0.4 }}
              aria-label="Twitter LinkPool"
              name="Twitter LinkPool">
              <x.img src={twitter} w="40px" h="40px"></x.img>
            </x.a>

            <x.a
              href="https://t.me/linkpoolio"
              target="_blank"
              rel="noopener noreferrer"
              opacity={{ _: 1, hover: 0.4 }}
              aria-label="Telegram LinkPool"
              name="Telegram LinkPool">
              <x.img src={tele} w="40px" h="40px"></x.img>
            </x.a>
          </x.div>
        </x.div>
      </x.div>

      <x.div>
        <x.span display="block" w="full" bg="vandog" h="2px"></x.span>
        <x.div py={{ _: '20px', lg: '45px' }} px={{ _: '30px', lg: '30px' }}>
          <x.p fontSize="14px" color="vandog" lineHeight="180%">
            Copyright: © 2023 LinkPool. All rights reserved.
          </x.p>
        </x.div>
      </x.div>
    </x.footer>
  )
}
