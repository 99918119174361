import React from 'react'
import { x } from '@xstyled/styled-components'
import { ButtonUnstyled } from '@pool/components/button/Button'
import { addZero } from '@pool/utils/numbers'
import { Plus } from '@pool/components/assets/Plus'
import { Minus } from '@pool/components/assets/Minus'

const list = [
  {
    q: 'How does LinkPool differentiate itself in the crypto market?',
    a: 'LinkPool is committed to providing advanced solutions, with a user-centric approach, driven by innovation and a deep understanding of the crypto realm.'
  },
  {
    q: 'What security measures does LinkPool adopt?',
    a: "LinkPool embraces cutting-edge security protocols, ensuring a top-tier, safe environment for our user's assets and data."
  },
  {
    q: 'How can I collaborate with LInkPool?',
    a: 'Join our community, participate in our programs, stake your assets, or simply reach out for any partnership inquiries.'
  }
]

export function Faq() {
  const [active, setActive] = React.useState(null)
  function handleStep(index) {
    setActive(index === active ? null : index)
  }

  return (
    <x.section
      id="faqs"
      display="flex"
      justifyContent="end"
      maxWidth="1348px"
      mx="auto"
      mt={{ _: '75px', lg: '150px' }}
      pt={{ _: '75px', lg: '150px' }}
      px="30px">
      <x.div w={{ _: '100%', lg: '900px' }}>
        <x.h2
          color="chime"
          fontSize={{ _: '20px', lg: '24px' }}
          fontWeight="600"
          lineHeight="100%"
          mb="33px">
          Questions?
        </x.h2>
        <x.h1
          fontSize={{ _: '50px', lg: '70px' }}
          fontWeight="500"
          lineHeight="100%"
          color="chime">
          We've got the insights.
        </x.h1>

        <x.div mt={{ _: '60px', lg: '115px' }}>
          <x.ul border="default" borderBottomColor="white-a20">
            {list.map((item, i) => {
              return (
                <x.li key={item.q}>
                  <ButtonUnstyled
                    w="100%"
                    border="default"
                    borderTopColor="white-a20"
                    display="flex"
                    onClick={() => handleStep(i)}
                    py="30px">
                    <x.span
                      pr={{ _: '20px', lg: '60px' }}
                      w={{ _: 'auto', lg: '110px' }}
                      textAlign="right"
                      color="chime"
                      fontSize="22px"
                      fontWeight="500"
                      lineHeight={{ _: '130%', lg: '100%' }}>
                      {addZero(i + 1)}
                    </x.span>
                    <x.p
                      color="montezuma"
                      fontSize="22px"
                      fontWeight="500"
                      lineHeight={{ _: '130%', lg: '100%' }}
                      flexGrow="1">
                      {item.q}
                    </x.p>
                    <x.span
                      color="chime"
                      display="flex"
                      alignItems="center"
                      pr="35px"
                      ml="20px"
                      h="24px">
                      {i === active ? <Minus /> : <Plus id="icon" />}
                    </x.span>
                  </ButtonUnstyled>
                  <x.div
                    display={i === active ? 'block' : 'none'}
                    ml={{ _: '43px', lg: '110px' }}
                    mt={{ _: '0px', lg: '30x' }}
                    mb="30px">
                    <x.p
                      color="montezuma"
                      fontSize={{ _: '18px', lg: '22px' }}
                      fontWeight="300"
                      lineHeight="195%">
                      {item.a}
                    </x.p>
                  </x.div>
                </x.li>
              )
            })}
          </x.ul>
        </x.div>
      </x.div>
    </x.section>
  )
}
