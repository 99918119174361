import React from 'react'
import { x } from '@xstyled/styled-components'

export function ArrowRightLong({ ...props }) {
  return (
    <x.svg
      width="72"
      height="16"
      viewBox="0 0 72 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.3431 0.92888L71.7071 7.29284C72.0976 7.68336 72.0976 8.31653 71.7071 8.70705L65.3431 15.071C64.9526 15.4615 64.3195 15.4615 63.9289 15.071C63.5384 14.6805 63.5384 14.0473 63.9289 13.6568L68.5858 8.99995H1C0.447715 8.99995 0 8.55223 0 7.99995C0 7.44766 0.447715 6.99995 1 6.99995H68.5858L63.9289 2.34309C63.5384 1.95257 63.5384 1.3194 63.9289 0.92888C64.3195 0.538355 64.9526 0.538355 65.3431 0.92888Z"
      />
    </x.svg>
  )
}
