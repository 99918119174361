import React from 'react'
import { x, useUp } from '@xstyled/styled-components'
import { Slider } from '@pool/components/slider/Slider'

const list = [
  {
    quote:
      "Our partnership with LinkPool enabled us to channel Accuweather's decades of meteorological expertise directly into the blockchain space, enabling us to foster innovation from parametric insurance in underserved markets to dynamic futures markets in developed regions. Together, we're pioneering the next chapter of meteorological innovation on the blockchain.",
    author: 'Matthew Vitebsky, Director of Flagship Products, AccuWeather'
  },
  {
    quote:
      "With LinkPool's robust infrastructure backing our Chainlink integration, we're taking a bold step into blockchain and smart contracts, fueling a dynamic future where data accuracy is paramount and trusted information meets decentralized innovation.",
    author:
      'Dwayne Desaulniers, Director of Blockchain and Data Licensing, Associated Press'
  },
  {
    quote:
      "LinkPool's expertise and dedication were instrumental in the seamless integration of our Flight Status Oracle with Chainlink. Beyond just the technical foundation, they offered nuanced insights into oracle deployment, paving the way for LexisNexis to harness the full potential of blockchain technology.",
    author: 'Gene Osgood, Data Scientist, LexisNexis'
  },
  // {
  //   quote:
  //     "Empowering blockchain advancements demands dependable data. With LinkPool's support, Google's Weather Oracle stands as a beacon for developers, underscoring our shared commitment to fostering trust and progression in this dynamic domain.",
  //   author: 'Allen Day, Developer Advocate, Google'
  // },
  {
    quote:
      "LinkPool has been instrumental in expanding Tiingo's Web3 strategy. By allowing us to abstract away the complexity of Web3, we have been able to distribute our products on multiple blockchains quickly, efficiently, and reliably. LinkPool is more than just a service provider, they represent a partnership built on trust and a shared vision for crypto's future.",
    author: 'Rishi Singh, Founder and CEO, Tiingo.'
  },
  {
    quote:
      "In today’s trust-driven digital economy, LinkPool's acumen in crypto integrations has empowered Thales to tap into decentralized oracles. Since partnering, Thales experienced 400% growth in TVL and tripled its user base, underscoring the power of secure, real-world data bridging.",
    author: 'Danijel Gornjakovic, Founder, Thales and Overtime Markets'
  }
]

export function Testimonials() {
  const upLg = useUp('lg')

  return (
    <x.section
      maxWidth="1560px"
      mx="auto"
      mt={{ _: '75px', lg: '150px' }}
      pt={{ _: '75px', lg: '150px' }}
      px="30px"
      id="testimonials">
      <x.div
        bg="montezuma"
        px={{ _: '30px', lg: '100px' }}
        py={{ _: '50px', lg: '100px' }}
        borderRadius="35px">
        <Slider
          animate={upLg ? true : false}
          buttonVariant="mustard"
          minHeight={{ _: '275px', '2xl': '375px' }}
          list={list}
          ItemComponent={(item, active) => {
            return (
              <x.article position={upLg ? 'absolute' : 'inherit'} key={active}>
                <x.p
                  mb="50px"
                  fontSize={{ _: '20px', '2xl': '32px' }}
                  fontWeight="500"
                  lineHeight="150%"
                  color="forest">
                  {item.quote}
                </x.p>
                <x.p
                  marginTop="auto"
                  fontSize={{ _: '20px', '2xl': '24px' }}
                  fontWeight="600"
                  lineHeight="120%"
                  color="mustard">
                  {item.author}
                </x.p>
              </x.article>
            )
          }}
        />
      </x.div>
    </x.section>
  )
}
